import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import i18nReducer from './i18n/intlSlice';
import userReducer from './user/userSlice';
import labelUsersReducer from './Labels/LabelUserSlice';
import labelUserCarriersReducer from './Labels/LabelUserCarrierSlice';
import labelUserBillsReducer from './Labels/LabelUserBillSlice';
import carriersReducer from './carriersSlice';
import platformCarriersReducer from './platformCarriersSlice';
import thirdpartyAccountReducer from './thirdparySlice';
import warehouseReducer from './warehouseSlice';
import inventoryReducer from './inventorySlice';
import warehouseCarrierReducer from './warehouseCarrierSlice';
import overseaWarehouseReducer from './overseaWarehouseSlice';
import hwcClientBillsReducer from './hwc/hwcClientBillSlice';
import hwcClientCarriersReducer from './hwc/hwcClientCarrierSlice';
import hwcClientWarehousesReducer from './hwc/hwcClientWarehouseSlice';
import hwcPartnerReducer from './hwcPartnerSlice';
import hwcPartnerWarehouseReducer from './hwcPartnerWarehouseSlice';
import hwcWarehouseReducer from './hwcWarehouseSlice';
import hwcWarehouseCarrierReducer from './hwcWarehouseCarrierSlice';
import hwcWarehouseFeeReducer from './hwcWarehouseFeeSlice';
import hwcProductReducer from './hwc/hwcProductSlice';
import hwcStockReduecer from './hwc/hwcStockSlice';
import mabangErpOrderReducer from './mabangErp/mabangErpOrderSlice';
import mabangErpStockReducer from './mabangErp/mabangErpStockSlice';
import hwcStockSyncReducer from './hwc/hwcStockSyncSlice';
import schedukleJobReducer from './scheduleJobSlice';

const rootReducer = combineReducers({
  i18n: i18nReducer,
  currentUser: userReducer,
  labelUsers: labelUsersReducer,
  labelUserCarriers: labelUserCarriersReducer,
  labelUserBills: labelUserBillsReducer,
  // wms: wmsReducer,
  carriers: carriersReducer,
  platformCarriers: platformCarriersReducer,
  thirdpartyAccounts: thirdpartyAccountReducer,
  warehouses: warehouseReducer,
  inventory: inventoryReducer,
  warehouseCarriers: warehouseCarrierReducer,
  overseaWarehouse: overseaWarehouseReducer,
  hwcClientBills: hwcClientBillsReducer,
  hwcClientCarriers: hwcClientCarriersReducer,
  hwcClientWarehouses: hwcClientWarehousesReducer,
  hwcPartners: hwcPartnerReducer,
  hwcPartnerWarehouses: hwcPartnerWarehouseReducer,
  hwcWarehouses: hwcWarehouseReducer,
  hwcWarehouseCarriers: hwcWarehouseCarrierReducer,
  hwcWarehouseFees: hwcWarehouseFeeReducer,
  hwcProducts: hwcProductReducer,
  hwcStocks: hwcStockReduecer,
  mabangErpOrder: mabangErpOrderReducer,
  mabangErpStock: mabangErpStockReducer,
  hwcStockSync: hwcStockSyncReducer,
  scheduleJob: schedukleJobReducer
  // users: usersReducer
});

const persistConfig = {
  key: 'pp_wms',
  storage,
  whitelist: ['currentUser', 'carriers']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer
});

export const persistor = persistStore(store);
