export const USER_GROUPS: Record<string, string> = {
  DEFAULT: '默认分组',
  CHINA_IMPORT: '中国进口'
};

export const CURRENCY_SIGNS: Record<string, string> = {
  USD: '$',
  RMB: '￥'
};

export const LABEL_USER_OPERATIONS = {
  PROFILE: '用户信息',
  CARRIERS: '物流设置',
  BILL: '账单信息'
};

export const WAREHOUSE_DETAIL_OOPERATIONS = {
  INVENTROY: '库存管理',
  VENDORS: '供应商管理',
  ORDERS: '订单管理',
  USERS: '用户管理',
  CARRIERS: '物流设置'
};

export const HWC_CLIENT_OPERATIONS = {
  USERS: '用户管理',
  WAREHOUSES: '仓库授权',
  PRODUCTS: '商品管理',
  TOU_CHENG: '头程订单',
  FULFILL: '发货订单',
  STOCK: '库存管理',
  BILLS: '账单管理',
  CARRIERS: '物流渠道',
  APIKEYS: 'API密钥',
  OPERATIONS: '操作日志'
};

export const HWC_CLIENT_WAREHOUSE_OPERATIONS = {
  OPERATIONAL_FEES: '仓库操作费',
  MABANG_ERP: '马帮ERP授权'
};

export const HWC_BILL_TYPES = {
  DELIVER: '代发订单',
  STORAGE: '仓储费',
  TOP_UP: '充值'
};

export const HWC_PARTNER_OPERATIONS = {
  WAREHOUSE: '仓库管理'
};

export const HWC_WAREHOUSES_OPERATIONS = {
  ERP: 'ERP 管理',
  CARRIERS: '物流渠道',
  STOCKS: '库存同步'
};
