export const LOCALES = {
  ENGLISH: 'en',
  CHINESE: 'zh-CN'
};

export const USER_ROLES = {
  SUPER_ADMIN: 'super_admin',
  LABEL_ADMIN: 'label_admin'
};

export const USER_ROLES_TEXT = {
  [USER_ROLES.SUPER_ADMIN]: 'Super Admin',
  [USER_ROLES.LABEL_ADMIN]: 'Label Admin'
};

export const LOCALE_DISPLAIES = {
  [LOCALES.ENGLISH]: 'English',
  [LOCALES.CHINESE]: '简体中文'
};

export const UI_ROUTES = {
  LOGIN: '/login',
  FORGOT: '/forgot',
  RESET: '/reset',
  ADMIN: '/admin',
  LABELS: '/labels',
  WMS: '/wms',
  DATA: '/data',
  USERS: '/users',
  CARRIERS: '/carriers',

  WAREHOUSES: '/warehouses',
  DASHBOARD: '/dashboard',
  VENDORS: '/vendors',
  REGISTER: '/register',
  ORDERS: '/orders',
  MANUAL: '/manual',
  CSV_IMPORT: '/csv-import',
  STEP_TWO: '/step-two',
  DETAIL: '/detail',
  EDIT: '/edit',
  SHIPMENTS: '/shipments',
  MANIFESTS: '/manifests',
  SETTINGS: '/settings',
  ACCOUNT: '/account',
  PROFILE: '/profile',
  BILLING: '/billing',
  // TODO STORES: '/stores',
  OVERSEA_WAREHOUSES: '/oversea-warehouses',
  OVERSEA_WAREHOUSES_CK: '/oversea-warehouses-ck',
  HWC_WAREHOUSES: '/hwc-warehouses',
  // HWC_WAREHOUSE_FEE: '/hwc-warehouse-fee',
  HWC_PARTNERS: '/hwc-partners',
  HWC_FEE: '/hwc-fee',
  ADDRESSES: '/addresses',
  PACKAGES: '/packages'
};

export const SETTING_KEYS = [
  `${UI_ROUTES.ACCOUNT}${UI_ROUTES.PROFILE}`,
  // TODO UI_ROUTES.STORES,
  UI_ROUTES.CARRIERS,
  UI_ROUTES.ADDRESSES,
  UI_ROUTES.LABELS,
  UI_ROUTES.PACKAGES
];

export const CARRIERS = {
  USPS: 'USPS',
  DHL_ECOM: 'DHL eCommerce',
  UPS: 'UPS',
  FEDEX: 'FedEx',
  PITNEY_BOWES: 'Pitney Bowes',
  RUI_YUN: 'Rui Yun',
  USPS3: 'USPS3'
};

export const DHL_ECOMMERCE_SERVICES = [
  { key: 'FLAT', name: 'DHL Smartmail Flats' },
  { key: 'EXP', name: 'DHL Parcel Expedited' },
  { key: 'MAX', name: 'DHL Parcel Expedited Max' },
  { key: 'GND', name: 'DHL Parcel Ground' },
  { key: 'PLT', name: 'DHL Parcel International Direct' },
  { key: 'PLY', name: 'DHL Parcel International Standard' },
  { key: 'PKY', name: 'DHL Packet International' }
];

export const UPS_SERVICES = [
  { key: 'Express', id: '07', name: 'UPS Worldwide Express' },
  { key: 'Expedited', id: '08', name: 'UPS Worldwide Expedited' },
  { key: 'Saver', id: '65', name: 'UPS Worldwide Saver' },
  { key: '2nd Day Air', id: '02', name: 'UPS 2nd Day Air' },
  { key: '2nd Day Air A.M.', id: '59', name: 'UPS 2nd Day Air A.M.' },
  { key: '3 Day Select', id: '12', name: 'UPS 3 Day Select' },
  { key: 'Ground', id: '03', name: 'UPS Ground' },
  { key: 'Next Day Air', id: '01', name: 'UPS Next Day Air' },
  { key: 'Next Day Air Early', id: '14', name: 'UPS Next Day Air Early' },
  { key: 'Next Day Air Saver', id: '13', name: 'UPS Next Day Air Saver' }
];

export const USPS_SERVICES = [
  { key: 'PMExpress', id: '3', name: 'Priority Mail Express' },
  { key: 'PM', id: '1', name: 'Priority Mail' },
  { key: 'FCM', id: '61', name: 'First Class Mail' },
  {
    key: 'PMExpress International',
    id: '1',
    name: 'Priority Mail Express International'
  },
  { key: 'PM International', id: '2', name: 'Priority Mail International' }
];

export const USPS_INTL_SERVICE_IDS = {
  EXPRESS_INTL: '1',
  PRIORITY_INTL: '2'
};

export const USPS_INTL_SERVICE_IDS_LIST = [
  USPS_INTL_SERVICE_IDS.EXPRESS_INTL,
  USPS_INTL_SERVICE_IDS.PRIORITY_INTL
];

export const FEDEX_SERVICES = [
  { key: 'INTERNATIONAL_ECONOMY', name: 'FedEx International Economy' },
  { key: 'INTERNATIONAL_PRIORITY', name: 'FedEx International PRIORITY' },
  { key: 'FEDEX_GROUND', name: 'FedEx Ground' },
  { key: 'GROUND_HOME_DELIVERY', name: 'FedEx Gound Home Delivery' },
  { key: 'SMART_POST', name: 'FedEx Ground Economy' }
];

export const RUI_YUN_SERVICES = [
  { key: 'UPS', id: 'AA06pkt03N', name: 'UPS MI JFK Q （MLF）08840' },
  { key: 'UPS', id: 'AA06pkt041', name: 'UPS MI CA  Q （MLF）91732' }
];

export const USPS3_SERVICES = [
  { key: 'USPS', id: '11429', name: 'GA' },
  { key: 'USPS', id: '11107', name: 'PM' }
];

export enum Currency {
  USD = 'USD',
  RMB = 'RMB'
}

export const CURRENCY_SIGNS: Record<string, string> = {
  [Currency.USD]: '$',
  [Currency.RMB]: '￥'
};

export enum CarrierRateType {
  FLAT = 'flat',
  PERSENTAGE = 'persentage'
}

export const RATE_BASES = {
  ORDER: '每票',
  PACKAGE: '每件',
  WEIGHT: '重量'
};

export enum Country {
  USA = 'US',
  CHINA = 'CN'
}

export const COUNTRY_NAMES = {
  [Country.USA]: 'United States',
  [Country.CHINA]: 'China'
};

export enum Store {
  PARCELSPRO = 'parcelspro',
  AMAZON = 'amazon',
  EBAY = 'ebay',
  SHOPIFY = 'shopify',
  CSV_IMPORT = 'CSV_Import'
}

export enum WeightUnit {
  G = 'g',
  KG = 'kg',
  OZ = 'oz',
  LB = 'lb'
}

export enum DistanceUnit {
  IN = 'in',
  CM = 'cm'
}

export enum OrderStatus {
  PENDING = 'Pending',
  FULFILLED = 'Fulfilled'
}

export const COUNTRIES = {
  [Country.USA]: 'United States',
  [Country.CHINA]: 'China'
};

export const STATES: Record<string, Record<string, string>> = {
  [Country.USA]: {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'DISTRICT OF COLUMBIA',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
  }
};

export const FILE_FORMATS = {
  standard: 'standard',
  thermal: 'thermal'
};

export const FILE_TYPES = {
  pdf: 'PDF',
  png: 'PNG',
  csv: 'CSV'
};

export const FILE_FORMAT_SIZES = {
  [FILE_FORMATS.standard]: [8.5, 11], // A4
  [FILE_FORMATS.thermal]: [4, 6]
};

export const FILE_FORMAT_SIZES_PDF_LIB = {
  [FILE_FORMATS.standard]: [595.28, 841.89], // A4
  [FILE_FORMATS.thermal]: [288, 432]
};

export const FILE_FORMAT_TEXTS = {
  [FILE_FORMATS.standard]: '8.5x11in',
  [FILE_FORMATS.thermal]: '4x6in'
};

export enum PackageTypes {
  PKG = 'PKG'
}

export const PACKAGE_TYPE_NAMES: Record<string, string> = {
  [PackageTypes.PKG]: 'Custom Dimensions'
};

export const PACKING_SLIP_FOMAT_SIZES = {
  [FILE_FORMATS.standard]: {
    fontSize: 10,
    header: {
      background: { x: 0.2, y: 0.2, w: 8.1, h: 0.21 },
      content: { x: 4.25, y: 0.3 }
    },
    sender: { x: 0.3, y: 0.6, step: 0.17 },
    orderInfo: { x: 6, y: 0.6, step: 0.17, distance: 0.1 },
    receipent: { title: { x: 2.5, y: 1.6 }, x: 3.2, y: 1.6, step: 0.17 },
    table: {
      header: { x: 0.3, y: 2.6, x2: 4.5, step: 1 },
      headerDivider: { x1: 0.3, y1: 2.7, x2: 8, y2: 2.7 },
      items: { x: 0.3, y: 3, x2: 4.5, x_step: 1, y_step: 0.2 }
    },
    foorterDivider: { x1: 0.3, x2: 8, y_step: 0.3 },
    subTotal: { x1: 6.5, x2: 7.5, y_step: 0.2 },
    sample: { x: 50, y: 10, angle: 30, font_size: 180 }
  },
  [FILE_FORMATS.thermal]: {
    fontSize: 8,
    header: {
      background: { x: 0, y: 0.1, w: 4, h: 0.21 },
      content: { x: 2, y: 0.2 }
    },
    sender: { x: 0.2, y: 1.2, step: 0.14 },
    orderInfo: { x: 1.1, y: 0.5, step: 0.14, distance: 0.05 },
    receipent: { title: { x: 1.9, y: 1.2 }, x: 2.2, y: 1.2, step: 0.14 },
    table: {
      header: { x: 0.1, y: 2.1, x2: 2.5, step: 0.5 },
      headerDivider: { x1: 0, y1: 2.2, x2: 4, y2: 2.2 },
      items: { x: 0.1, y: 2.4, x2: 2.5, x_step: 0.5, y_step: 0.2 }
    },
    foorterDivider: { x1: 0, x2: 4, y_step: 0.2 },
    subTotal: { x1: 2.5, x2: 3.5, y_step: 0.2 },
    sample: { x: 30, y: 10, angle: 30, font_size: 80 }
  }
};

export const CARRIER_REGIONS = {
  US_DOMESTIC: 'US_DOMESTIC',
  US_INTERNATIONAL: 'US_INTERNATIONAL',
  CN_IMPORT: 'CN_IMPORT',
  CN_DOMESTIC: 'CN_DOMESTIC',
  CN_INTERNATIONAL: 'CN_INTERNATIONAL'
};

export const CARRIER_REGIONS_TEXTS = {
  [CARRIER_REGIONS.US_DOMESTIC]: 'US Domestic',
  [CARRIER_REGIONS.US_INTERNATIONAL]: 'US International',
  [CARRIER_REGIONS.CN_IMPORT]: 'China Import',
  [CARRIER_REGIONS.CN_DOMESTIC]: 'China Domestic',
  [CARRIER_REGIONS.CN_INTERNATIONAL]: 'China International'
};

export enum TransactionStatus {
  PAID = 'Paid',
  CREDITED = 'Credited',
  PENDING = 'Pending'
}

export const TRANSACTION_STATUS_COLORS: Record<string, string> = {
  [TransactionStatus.PAID]: '#2b7d2f',
  [TransactionStatus.CREDITED]: '#5eb761'
};

export const LABEL_STATUS = {
  SUCCESSFUL: 'Successful',
  REFUND_REQUESTED: 'Refund Requested',
  REFUND_PENDING: 'Refund Pending',
  REFUNDED: 'Refunded',
  ERROR: 'Error'
};

export const SERVER_ROUTES = {
  CARRIER: '/carriers',
  PLATFORM_CARRIERS: '/platformCarriers',
  CARRIER_SETTINGS: '/carrierSettings',
  USERS: '/users',
  WMS_USERS: '/wms_users',
  BILLINGS: '/billings',
  PRINT_FORMAT: '/printFormat',
  PACKAGE: '/packages',
  ADDRESSES: '/addresses',
  ORDERS: '/orders',
  ORDER_ITEMS: '/items',
  SHIPMENTS: '/shipments',
  PRELOAD: '/preload',
  CSV: '/csv',
  ZONE: '/zone',
  STATIC: '/static',
  WMS: '/wms',
  WAREHOUSES: '/warehouses',
  OVERSEA_WAREHOUSES: '/overseaWarehouses',
  OVERSEA_WAREHOUSE_USERS: '/overseaWarehouseUsers',
  HWC_CLIENT_BILLS: '/hwcClientBills',
  HWC_CLIENT_CARRIERS: '/hwcClientCarriers',
  HWC_CLIENT_WAREHOUSES: '/hwcClientWarehouses',
  HWC_PARTNERS: '/hwcPartners',
  HWC_PARTNER_WAREHOUSES: '/hwcPartnerWarehouses',
  HWC_WAREHOUSES: '/hwcWarehouses',
  HWC_WAREHOUSE_CARRIERS: '/hwcWarehouseCarriers',
  HWC_WAREHOUSE_FEE: '/hwcWarehouseFee',
  HWC_PRODUCTS: '/hwcProducts',
  HWc_STOCKS: '/hwcStocks',
  HWC_STOCKS_SYNC: '/hwcStockSync',
  HWC_SYNC_RECORDS: '/hwcSyncRecords',
  INVENTORY: '/inventory',
  WAREHOUSE_CARRIERS: '/warehouseCarriers',
  WAREHOUSE_USERS: '/warehouseUsers',
  LABEL_USERS: '/labelUsers',
  LABEL_USER_CARRIERS: '/labelUserCarriers',
  LABEL_USER_BILLS: '/labelUserBills',
  THIRDPARTY_ACCOUNTS: '/thirdparties',
  API: '/api',
  MA_BANG_ERP: '/mabangErp',
  SCHEDULE_JOBS: '/schedule-job'
};

export const DEFAULT_SERVER_HOST =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BACKEND_URL
    : 'http://localhost:4000';

export const CSV_SAMPLE_FILE = 'parcelspro_sample_csv.csv';

export const CSV_TITLE_OPTIONS = [
  { name: 'Order ID', value: 'orderId', required: false },
  { name: 'Order Date', value: 'orderDate', required: false },
  { name: 'Recipient Name', value: 'recipientName', required: true },
  { name: 'Company', value: 'company', required: false },
  { name: 'Email', value: 'email', required: false },
  { name: 'Phone', value: 'phone', required: false },
  { name: 'Street Line 1', value: 'street1', required: true },
  { name: 'Street Line 2', value: 'street2', required: false },
  { name: 'City', value: 'city', required: true },
  { name: 'State/Province', value: 'state', required: true },
  { name: 'Zip/Postal Code', value: 'zip', required: true },
  { name: 'Country', value: 'country', required: true },
  { name: 'Item Title', value: 'itemTitle', required: false },
  { name: 'Quantity', value: 'quantity', required: false },
  { name: 'Item Weight', value: 'itemWeight', required: false },
  { name: 'Item Weight Unit', value: 'itemWeightUnit', required: false },
  { name: 'Item Price', value: 'itemValue', required: false },
  { name: 'Item Currency', value: 'itemValueCurrency', required: false },
  { name: 'SKU', value: 'sku', required: false },
  { name: 'Order Weight', value: 'orderWeight', required: false },
  { name: 'Order Weight Unit', value: 'orderWeightUnit', required: false },
  { name: 'Order Amount', value: 'orderAmount', required: false },
  { name: 'Order Currency', value: 'orderCurrency', required: false },
  { name: 'Ignore', value: 'ignore', required: false }
];

export const HTTP_ERROR_CODE_MESSAGE: { [key: number]: string } = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  405: '请求方法不被允许。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
};

export const REST_ERROR_CODE: Record<string, string> = {
  EMAIL_IN_USE: 'Email already in use',
  PASSWORD_MISMATCH: 'Password mismatch',
  INVALID_TOKEN: 'Failed to reset the password',
  EMAIL_NOT_FOUND: 'Email is not found'
};

// Custom Constents
export const TYPE_OF_CONTENT: Record<string, string> = {
  DOCUMENTS: 'Documents',
  GIFT: 'Gift',
  SAMPLE: 'Sample',
  MERCHANDISE: 'Merchandise',
  RETURN: 'Return merchandise',
  DONATION: 'Humanitarian donation',
  OTHER: 'Other'
};

export const INCOTERM: Record<string, Record<string, string>> = {
  DDU: {
    name: 'DDU(bill recipient)',
    value: 'DDU'
  },
  DDP: {
    name: 'DDP(bill sender)',
    value: 'DDP'
  }
};

export const NON_DELIVERY_HANDLING: Record<string, string> = {
  RETURN: 'Return',
  ABANDON: 'Abandon'
};

export const TAX_ID_TYPE: Record<string, string> = {
  VAT: 'VAT',
  EIN: 'EIN'
};

export const EEL_PFC: Record<string, string> = {
  N37A: 'NOEEI 30 37(a)',
  N37F: 'NOEEI 30 37(f)',
  N37H: 'NOEEI 30 37(h)',
  N36: 'NOEEI 30 36',
  AES: 'AES/ITN'
};

export const B13A_OPTION: Record<string, string> = {
  FILED_ELECTRONICALLY: 'Filed electronically',
  SUMMARY_REPORTING: 'Summary reporting',
  NOT_REQUIRED: 'Not Required'
};

export const BIZ_TYPES = {
  LABEL: 1,
  WMS: 2
};

export const HWC_CLIENT_BILL_TYPE = {
  DAI_FA: '代发',
  SHIPPING: '物流费',
  STORAGE: '仓储费',
  OTHER: '其他费用',
  RECHARGE: '余额调整'
};

export const FEE_ITEM_NAMES = {
  baseFee: '基础费用',
  countingFee: '清点费',
  skuFee: '多SKU费用',
  packingFee: '包装费',
  labelFee: '自提面单费',
  returnFee: '退货费',
  storageFee: '仓储费',
  otherFees: '其他费用'
};

export const FEE_ITEM_PROPS: Record<string, string> = {
  基础费用: 'baseFee',
  清点费: 'countingFee',
  多SKU费用: 'skuFee',
  包装费: 'packingFee',
  自提面单费: 'labelFee',
  退货费: 'returnFee',
  仓储费: 'storageFee'
};

export const FEE_ITEM_DESCRIPTIONS = {
  baseFee: '包含货物出库分拣',
  countingFee: '针对多sku产品入仓收费，单一sku产品不收取',
  skuFee: '一票多件包裹，每增加一件产品+0.3美金/件',
  packingFee:
    '包含防水袋及打包费用（客户自己打包好到仓库不收取)；客户自行提供防水袋，收取0.3美金/件打包费',
  labelFee: '非使用我司快递面单的自提操作费用',
  returnFee:
    '含清点费0.3美金/件，包装费如上，纸箱或者防水袋收费（防水袋0.5美金/个，箱子费用由箱子价格+3美金/箱打包费组成',
  storageFee: '/cbm/天'
};

export const TIMEZONE = [
  { label: '中国北京时间(上海,香港)', value: 'Asia/Shanghai' },
  { label: '美国东部时间(纽约)', value: 'America/New_York' },
  { label: '美国中部时间(芝加哥)', value: 'America/Chicago' },
  { label: '美国西部时间(洛杉矶)', value: 'America/Los_Angeles' }
];

export const CARRIER_ZONE_MODE = {
  ZIP: 'zip',
  COUNTRY: 'country'
};

export const CARRIER_ZONE_MODE_TEXTS = {
  [CARRIER_ZONE_MODE.ZIP]: '邮编',
  [CARRIER_ZONE_MODE.COUNTRY]: '国家'
};
