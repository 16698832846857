import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Form, Input, Space, Select, Checkbox, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Country, COUNTRY_NAMES } from '../../../../shared/utils/constants';
import {
  createHwcWarehouseHandler,
  selectCreateHwcWareHouseModal,
  selectHwcWarehouseModalLoading,
  setCreateHwcWarehouseModal
} from '../../../../redux/hwcWarehouseSlice';
import { HwcPartnerWarehouse, HwcWarehouseData } from '../../../../custom_types/redux-types';
import { selectHwcPartners } from '../../../../redux/hwcPartnerSlice';
import { HwcPartner } from '../../../../custom_types/overseawarehouse/hwc-partner';
import { selectAllHwcPartnerWarehouses } from '../../../../redux/hwcPartnerWarehouseSlice';

const { Option } = Select;

const HwcWarehouseCreateModal = (): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const showModal = useSelector(selectCreateHwcWareHouseModal);
  const modalLoading = useSelector(selectHwcWarehouseModalLoading);
  const hwcParterList = useSelector(selectHwcPartners);
  const hwcPartnerWarehouseList = useSelector(selectAllHwcPartnerWarehouses);
  const [showThirdpartyFields, setShowThirdpartyFields] = useState(false);
  const [selectedHwcPartner, setSelectedHwcPartner] = useState<HwcPartner | undefined>();
  const [selectedHwcPartnerWarehouse, setSelectedHwcPartnerWarehouse] = useState<
    HwcPartnerWarehouse | undefined
  >();

  useEffect(() => {
    form.resetFields();
  }, [form, dispatch]);

  const cancelClickedHandler = () => {
    form.resetFields();
    dispatch(setCreateHwcWarehouseModal(false));
  };

  const hwcPartnerSelectedHandler = (value: string) => {
    const partner = hwcParterList.find(
      (item) => item.code === value.split('_')[1] && item.account === value.split('_')[2]
    );
    setSelectedHwcPartner(partner);
    setSelectedHwcPartnerWarehouse(undefined);
  };

  const hwcWarehouseSelectedHandler = (value: string) => {
    const warehouse = hwcPartnerWarehouseList.find((item) => item.code === value);
    setSelectedHwcPartnerWarehouse(warehouse);
    if (selectedHwcPartner && warehouse) {
      form.setFieldsValue({
        name: `${selectedHwcPartner.account}${selectedHwcPartner.code}-${warehouse.code}`,
        code: `${selectedHwcPartner.code}-${warehouse.code}-${selectedHwcPartner.account}`,
        contact: warehouse.contact,
        phone: warehouse.phone,
        email: warehouse.email,
        address1: warehouse.address1,
        address2: warehouse.address2,
        city: warehouse.city,
        state: warehouse.state,
        zip: warehouse.zip,
        country: warehouse.country
      });
    }
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedHwcPartner && selectedHwcPartnerWarehouse) {
          const data: HwcWarehouseData = {
            name: values.name,
            code: values.code,
            thirdParty: showThirdpartyFields,
            thirdPartyParnter: `${selectedHwcPartner.name}_${selectedHwcPartner.code}_${selectedHwcPartner.account}`,
            thirdPartyCode: selectedHwcPartnerWarehouse.code,
            contact: values.contact,
            phone: values.phone,
            email: values.email,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zip,
            country: values.country,
            mabangAppKey: values.mabangAppKey
          };
          dispatch(createHwcWarehouseHandler(data));
          form.resetFields();
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      centered
      closable={false}
      open={showModal}
      okText="添加仓库"
      cancelText="取消"
      title="添加仓库信息"
      onCancel={cancelClickedHandler}
      onOk={okClickedHandler}
      okButtonProps={{ loading: modalLoading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="thirdParty">
          <Space size="middle">
            <div>第三方仓库</div>
            <Checkbox
              onChange={(e) =>
                e.target.checked ? setShowThirdpartyFields(true) : setShowThirdpartyFields(false)
              }
            />
          </Space>
        </Form.Item>
        {showThirdpartyFields && (
          <Space size="large">
            <Form.Item label="合作海外仓" name="thirdPartyParnter">
              <Select
                placeholder="合作海外仓"
                style={{ width: 180 }}
                onChange={(value: string) => hwcPartnerSelectedHandler(value)}
              >
                {hwcParterList.map((item) => (
                  <Option key={item.id} value={`${item.name}_${item.code}_${item.account}`}>
                    {`${item.name}_${item.code}_${item.account}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="选择仓库" name="thirdPartyCode">
              <Select
                placeholder="选择仓库"
                style={{ width: 180 }}
                onChange={(value: string) => hwcWarehouseSelectedHandler(value)}
              >
                {hwcPartnerWarehouseList.map((item) => {
                  if (item.hwcPartnerRef === selectedHwcPartner?.id) {
                    return (
                      <Option key={item.id} value={`${item.code}`}>
                        {item.code}
                      </Option>
                    );
                  }
                  return null;
                })}
              </Select>
            </Form.Item>
          </Space>
        )}
        <Space size="large">
          <Form.Item
            label="仓库名称"
            name="name"
            rules={[{ required: true, message: '仓库名称必须填' }]}
          >
            <Input placeholder="仓库名称" autoFocus disabled={showThirdpartyFields} />
          </Form.Item>
          <Form.Item
            label="仓库代码"
            name="code"
            rules={[{ required: true, message: '仓库代码必须填' }]}
          >
            <Input placeholder="仓库代码" disabled={showThirdpartyFields} />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item
            label="联系人"
            name="contact"
            rules={[{ required: true, message: '联系人必须填' }]}
          >
            <Input placeholder="联系人" disabled={showThirdpartyFields} />
          </Form.Item>
          <Form.Item label="电话" name="phone">
            <Input placeholder="电话" disabled={showThirdpartyFields} />
          </Form.Item>
        </Space>
        <Form.Item label="邮箱" name="email">
          <Input placeholder="邮箱" disabled={showThirdpartyFields} />
        </Form.Item>
        <Form.Item
          label="地址1"
          name="address1"
          rules={[{ required: true, message: '地址1必须填' }]}
        >
          <Input placeholder="地址1" disabled={showThirdpartyFields} />
        </Form.Item>
        <Form.Item label="地址2" name="address2">
          <Input placeholder="地址2" disabled={showThirdpartyFields} />
        </Form.Item>
        <Space size="large">
          <Form.Item label="城市" name="city" rules={[{ required: true, message: '城市必须填' }]}>
            <Input placeholder="城市" disabled={showThirdpartyFields} />
          </Form.Item>
          <Form.Item label="州" name="state" rules={[{ required: true, message: '州必须填' }]}>
            <Input placeholder="州" disabled={showThirdpartyFields} />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item label="邮编" name="zip" rules={[{ required: true, message: '邮编必须填' }]}>
            <Input placeholder="邮编" disabled={showThirdpartyFields} />
          </Form.Item>
          <Form.Item
            label="国家"
            name="country"
            rules={[{ required: true, message: '国家必须填' }]}
          >
            <Select placeholder="国家" style={{ width: 180 }} disabled={showThirdpartyFields}>
              <Option value={Country.USA}>{COUNTRY_NAMES[Country.USA]}</Option>
              <Option value={Country.CHINA}>{COUNTRY_NAMES[Country.CHINA]}</Option>
            </Select>
          </Form.Item>
        </Space>
        <Divider orientation="left">ERP信息</Divider>
        <Form.Item label="马帮 appKey" name="mabangAppKey">
          <Input placeholder="马帮 appKey" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HwcWarehouseCreateModal;
