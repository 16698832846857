import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Form, Input, Space, Select, Divider, InputNumber, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  CarrierRateType,
  Country,
  COUNTRY_NAMES,
  Currency,
  RATE_BASES
} from '../../../../shared/utils/constants';
import { selectHwcWarehouses } from '../../../../redux/hwcWarehouseSlice';
import {
  HwcClientWarehouseChannel,
  HwcClientWarehouseData,
  HwcWarehouse
} from '../../../../custom_types/redux-types';
import {
  createHwcClientWarehouseHandler,
  selectHwcClientWarehouseModalLoading,
  selectShowCreateHwcClientWarehouseModal,
  setShowCreateHwcClientWarehouseModal
} from '../../../../redux/hwc/hwcClientWarehouseSlice';
import {
  fetchHwcWarehouseCarriersHandler,
  selectHwcWarehouseCarriers
} from '../../../../redux/hwcWarehouseCarrierSlice';

interface HwcClientWarehouseCreateModalProps {
  hwcClientId: string;
}

const { Option } = Select;

const HwcClientWarehouseCreateModal = ({
  hwcClientId
}: HwcClientWarehouseCreateModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const showModal = useSelector(selectShowCreateHwcClientWarehouseModal);
  const modalLoading = useSelector(selectHwcClientWarehouseModalLoading);
  const warehouses = useSelector(selectHwcWarehouses);
  const [selectedWarehouse, setSelectedWarehouse] = useState<HwcWarehouse | undefined>();
  const warehouseCarriers = useSelector(selectHwcWarehouseCarriers);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const cancelClickedHandler = () => {
    form.resetFields();
    dispatch(setShowCreateHwcClientWarehouseModal(false));
  };

  const warehouseSelectedHandler = (value: string) => {
    const w = warehouses.find((warehouse) => warehouse.code === value);
    setSelectedWarehouse(w);
    if (w) dispatch(fetchHwcWarehouseCarriersHandler(w.id));
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedWarehouse && warehouseCarriers) {
          const clist = warehouseCarriers.map(
            (channel): HwcClientWarehouseChannel => {
              const ratebase = values[`${channel.code}-ratebase`];
              const currency = values[`${channel.code}-currency`];
              const rate = values[`${channel.code}-rate`];
              const ratetype = values[`${channel.code}-ratetype`];
              return {
                name: channel.code,
                fee: {
                  ratebase,
                  currency,
                  rate,
                  ratetype
                }
              };
            }
          );

          const data: HwcClientWarehouseData = {
            name: selectedWarehouse.name,
            code: selectedWarehouse.code,
            active: true,
            channels: clist,
            hwcClientRef: hwcClientId
          };
          dispatch(createHwcClientWarehouseHandler(data));
          form.resetFields();
        }
      })
      .catch(() => {});
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      centered
      closable={false}
      open={showModal}
      okText="仓库授权"
      cancelText="取消"
      title="授权仓库信息"
      onCancel={cancelClickedHandler}
      onOk={okClickedHandler}
      okButtonProps={{ loading: modalLoading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="仓库列表"
          name="warehouse"
          rules={[{ required: true, message: '仓库必须填' }]}
        >
          <Select
            placeholder="仓库"
            style={{ width: 180 }}
            onChange={(value) => warehouseSelectedHandler(value)}
          >
            {warehouses.map((warehouse) => (
              <Option key={warehouse.code} value={warehouse.code}>
                {warehouse.name}
              </Option>
            ))}
          </Select>
          {selectedWarehouse && warehouseCarriers && (
            <>
              <Divider>渠道费率</Divider>
              {warehouseCarriers.map((channel) => {
                return (
                  <>
                    <div>
                      <strong>渠道名称： {`${channel.name}-${channel.code}`}</strong>
                    </div>
                    <Space size="large" align="baseline" style={{ width: '100%' }}>
                      <Form.Item
                        name={`${channel.code}-ratebase`}
                        initialValue={RATE_BASES.ORDER}
                        rules={[{ required: true, message: '收费基准必须选' }]}
                      >
                        <Input style={{ width: '180px' }} type="text" disabled />
                      </Form.Item>
                      <Form.Item
                        name={`${channel.code}-currency`}
                        initialValue={Currency.USD}
                        rules={[{ required: true, message: '货币种类必须填' }]}
                      >
                        <Input style={{ width: '80px' }} type="text" disabled />
                      </Form.Item>
                    </Space>
                    <Space size="large" align="baseline" style={{ width: '100%' }}>
                      <Form.Item
                        name={`${channel.code}-rate`}
                        rules={[{ required: true, message: '费率必须填！' }]}
                      >
                        <InputNumber
                          placeholder="费率"
                          min={0}
                          style={{ width: '100px' }}
                          step="0.01"
                          precision={2}
                        />
                      </Form.Item>
                      <Form.Item
                        name={`${channel.code}-ratetype`}
                        rules={[{ required: true, message: '请选择费率模式' }]}
                      >
                        <Radio.Group>
                          {Object.values(CarrierRateType).map((item) => {
                            return (
                              <Radio key={item} value={item}>
                                {item}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                    <Divider />
                  </>
                );
              })}
            </>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HwcClientWarehouseCreateModal;
